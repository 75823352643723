import React, { useState, useEffect } from "react";
import { TicketAmountSelector } from "./TicketAmountSelector/TicketAmountSelector";
import { TicketDatePicker } from "./TicketDatepicker/TicketDatepicker";
import { TicketProps } from "./TicketProps.csharp";
import { TicketSummary } from "./TicketSummary/TicketSummary";
import { getAvailableTimeSlots } from "./api/post";
import { getTickets } from "./api/get";
import { TicketMember } from "./TicketMember/TicketMember";
import { getAvailableDates } from "./api/post";
import { ErrorMessage } from "../Shared/ErrorMessage";
import { formatTime } from "./utils/formatTime";
import moment = require("moment");
interface IPrice {
    price: number;
    price_incl_vat: number;
    qty: number;
}

export interface ITicket {
    brackets: IPrice[];
    id: number;
    name: string;
    count: number;
    package_product_id: number;
    admission_codes: string[];
    qty: {
        default: number;
        individual: number;
        max: number;
        min: number;
    };
    requires: number[];
    sku;
}

export interface ITimeSlot {
    admission_code: string;
    end_utc_timestamp: number;
    no: string;
    remaining_slots: number;
    salable_from_utc_timestamp: number;
    salable_until_utc_timestamp: number;
    schedule_code: string;
    start_date: Date;
    start_utc_timestamp: number;
}

interface IAdmission {
    code: string;
    date_selection_required: boolean;
    description: string;
    schedule_selection_required: boolean;
}

export const TicketPage = (props: TicketProps) => {
    const isMember = props.isMember;
    const [showAmountSelector, setShowAmountSelector] = useState<boolean>(!isMember);
    const [isExpress, setIsExpress] = useState<boolean>(props.ticketAmountSelectorProps.express);
    const [ticketData, setTicketData] = useState<ITicket[]>([]);
    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [selectedTime, setSelectedTime] = useState<ITimeSlot | null>();
    const [selectedDate, setSelectedDate] = useState<Date>(null);
    const [availableTimeSlots, setAvailableTimeSlots] = useState<ITimeSlot[]>([]);
    const [allAvailableDates, setAllAvailableDates] = useState<Date[]>([]);
    const [normalTicketId, setNormalTicketId] = useState<number>();
    const [availableDates, setAvailableDates] = useState<Date[]>([]);
    const [setupError, setSetupError] = useState(false);
    const [datesError, setDatesError] = useState(false);
    const [schedulesError, setSchedulesError] = useState(false);
    const [noTimeslotsError, setNoTimeslotsError] = useState(false);
    const [ticketAmountLoading, setTicketAmountLoading] = useState(false);
    const [ticketSoldOut, setTicketSoldOut] = useState(false);
    const [ticketNotExists, setTicketNotExists] = useState(false);
    const [ticketExpired, setTicketExpired] = useState(false);
    const [dateLoading, setDateLoading] = useState(false);
    const [timeSlotLoading, setTimeSlotLoading] = useState(false);
    const [admissions, setAdmissions] = useState<IAdmission[]>();
    const ticketCountArray = ticketData.map((ticket) => ticket.count);
    const numberOfTickets = ticketCountArray.reduce((a, b) => a + b, 0);
    const accessToken = props.accessToken;

    const noDateAndScheduleRequired =
        admissions && !admissions[0].date_selection_required && !admissions[0].schedule_selection_required;

    useEffect(() => {
        (async () => {
            try {
                const timeout = setTimeout(() => setDateLoading(true), 300);
                const dates = await getAvailableDates(
                    ticketData,
                    props.ticketDatePickerProps.endpoint,
                    props.accessToken,
                    setDatesError
                );

                setAvailableDates(dates.map((d: string) => new Date(d)));
                clearTimeout(timeout);
                setDateLoading(false);
            } catch (error) {
                console.log(error);
                setDatesError(true);
            }
        })();
    }, [datePickerIsOpen, ticketData]);

    useEffect(() => {
        (async () => {
            try {
                const timeout = setTimeout(() => setTimeSlotLoading(true), 300);
                const timeslots = await getAvailableTimeSlots(
                    props.ticketTimeSlotSelectorProps.endpoint,
                    selectedDate ? selectedDate : availableDates.length !== 0 ? availableDates[0] : new Date(),
                    ticketData,
                    isExpress,
                    props.regularTicketPageLink,
                    accessToken,
                    setSchedulesError,
                    setNoTimeslotsError
                );

                setAvailableTimeSlots(timeslots);
                clearTimeout(timeout);
                setTimeSlotLoading(false);
                if (!isExpress) {
                    setShowSummary(false);
                    setSelectedTime(null);
                }
                if (noDateAndScheduleRequired) {
                    setSelectedTime(timeslots[0]);
                }
            } catch (error) {
                setTimeSlotLoading(false);
                console.log(error);
            }
        })();
    }, [isExpress && ticketData, selectedDate]);

    useEffect(() => {
        if (isExpress && selectedDate && ticketData.some((data) => data.count !== 0)) {
            setSelectedTime(availableTimeSlots.find((timeSlot) => timeSlot.remaining_slots >= numberOfTickets));
            setShowSummary(!!availableTimeSlots.find((timeSlot) => timeSlot.remaining_slots >= numberOfTickets));
        }
    }, [availableTimeSlots]);

    const redirectToLoginPage = () => {
        if (window) {
            const currentURL = new URL(window.location.href);
            const currentHost = currentURL.origin;
            const pathnameWithQuery = currentURL.pathname + currentURL.search;
            const logInUrl = props.loginLogoutViewModel?.logInUrl;
            const logInUrlWithReturnParameter = currentHost + logInUrl + "?ReturnUrl=" + pathnameWithQuery;

            if (logInUrl && logInUrlWithReturnParameter) {
                location.href = logInUrlWithReturnParameter;
            }
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const timeout = setTimeout(() => setTicketAmountLoading(true), 300);

                const [data, admissions, response] = await getTickets(
                    props.ticketAmountSelectorProps.endpoint,
                    props.accessToken,
                    setSetupError
                );

                if (response.message !== undefined) {
                    if (response.message.includes("The product that was requested doesn't exist")) {
                        setTicketNotExists(true);
                    }
                }

                if (response.toString() === "0") {
                    setTicketSoldOut(true);
                }

                if (response.toString() === "-1") {
                    setTicketExpired(true);
                }

                if (response.toString() === "-2") {
                    redirectToLoginPage();
                }

                if (!ticketData.find((ticket) => ticket.package_product_id === data[0].package_product_id)) {
                    setNormalTicketId(data[0].package_product_id);
                    setTicketData((oldData) => [...oldData, ...data]);
                }
                setAdmissions(admissions);
                clearTimeout(timeout);
                setTicketAmountLoading(false);
            } catch (error) {
                setSetupError(true);
            }
        })();
    }, []);

    useEffect(() => {
        if (ticketData.every((data) => data.count === 0)) {
            setDatePickerIsOpen(false);
            setShowSummary(false);
            if (!noDateAndScheduleRequired) {
                if (availableDates.length > 1) setSelectedDate(null);
                setSelectedTime(null);
            }
        }
    }, [ticketData]);

    useEffect(() => {
        try {
            (async () => {
                const dates = (
                    await getAvailableDates(
                        ticketData,
                        props.ticketDatePickerProps.endpoint,
                        props.accessToken,
                        setDatesError
                    )
                ).map((d: string) => new Date(d));
                setAllAvailableDates(dates);
                if ((dates.length === 1 && admissions[0].date_selection_required) || noDateAndScheduleRequired) {
                    setAvailableDates(dates);
                    setSelectedDate(dates[0]);
                }
            })();
        } catch (e) {
            console.log(e);
        }
    }, [admissions]);

    const isGiftMembership = ticketData?.[0]?.package_product_id === 558;
    return (
        <div className="TicketPage">
            <div className="TicketPage__Container">
                <div className="TicketPage__wrapper">
                    <section className="TicketPage__hero">
                        <h1 className="TicketPage__title">
                            {props.ticketEventProps ? props.ticketEventProps.title : props.title}
                        </h1>
                        {!isGiftMembership && selectedDate && allAvailableDates.length === 1 && (
                            <p className="TicketPage__date">
                                {moment(selectedDate).format("D.MM.YYYY")}

                                {selectedTime &&
                                    noDateAndScheduleRequired &&
                                    ", kl." + formatTime(selectedTime.start_utc_timestamp)}
                            </p>
                        )}
                        {props.ticketEventProps ? (
                            <div dangerouslySetInnerHTML={{ __html: props.ticketEventProps.shortDescription }} />
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: props.ingress }} />
                        )}
                    </section>
                    {isMember ? (
                        <TicketMember
                            availableDates={availableDates}
                            setDatePickerIsOpen={setDatePickerIsOpen}
                            setShowAmountSelector={setShowAmountSelector}
                            setIsExpress={setIsExpress}
                            availableTimeSlots={availableTimeSlots}
                            numberOfTickets={numberOfTickets}
                            setSelectedTime={setSelectedTime}
                            setSelectedDate={setSelectedDate}
                            setTicketData={setTicketData}
                            setSetupError={setSetupError}
                            ticketData={ticketData}
                            editorialProps={{
                                express: props.ticketAmountSelectorProps.express,
                                entryLaterButtonText: props.ticketAmountSelectorProps.entryLaterButtonText,
                                entryNowButtonText: props.ticketAmountSelectorProps.entryNowButtonText,
                                selectDateTimeButtonText: props.ticketAmountSelectorProps.selectDateTimeButtonText,
                                firstAvailableButtonText: props.ticketAmountSelectorProps.firstAvailableButtonText,
                                endpoint: props.ticketMemberProps.endpoint,
                                numberOfMemberTicketText: props.ticketMemberProps.numberOfMemberTicketText,
                                oneTicket: props.ticketMemberProps.oneTicket,
                                twoTickets: props.ticketMemberProps.twoTickets,
                                accessToken: props.accessToken,
                            }}
                            setAvailableDates={setAvailableDates}
                            setDatesError={setDatesError}
                            datePickerEndpoint={props.ticketDatePickerProps.endpoint}
                            setShowSummary={setShowSummary}
                        />
                    ) : (
                        <TicketAmountSelector
                            ticketData={ticketData}
                            setTicketData={setTicketData}
                            editorialProps={{
                                ...props.ticketAmountSelectorProps,
                                maxTotalTickets: props.maxTotalTickets,
                                maxTotalTicketsExceededError: props.maxTotalTicketsExceededError,
                                loginLinkText: props.loginLinkText,
                                logoutLinkText: props.logoutLinkText,
                            }}
                            setDatePickerIsOpen={setDatePickerIsOpen}
                            setIsExpress={setIsExpress}
                            setSelectedTime={setSelectedTime}
                            numberOfTickets={numberOfTickets}
                            setSelectedDate={setSelectedDate}
                            isMember={isMember}
                            availableTimeSlots={availableTimeSlots}
                            normalTicketId={normalTicketId}
                            isLoading={ticketAmountLoading}
                            isSoldOut={ticketSoldOut}
                            soldOutText={props.ticketTimeSlotSelectorProps.soldOutText}
                            isExpired={ticketExpired}
                            expiredText={props.ticketSummaryProps.ticketExpiredText}
                            notExists={ticketNotExists}
                            notExistsText={props.ticketSummaryProps.ticketNotExistsText}
                            freeText={props.freeText}
                            noDateAndScheduleRequired={noDateAndScheduleRequired}
                            setShowSummary={setShowSummary}
                            availableDates={availableDates}
                            ticketEventProps={props.ticketEventProps}
                            signLink={{
                                signInUrl: props.loginLogoutViewModel.logInUrl,
                                signOutUrl: props.loginLogoutViewModel.logOutUrl,
                                signOutLabel: props.loginLogoutViewModel.logOutText,
                                signInLabel: props.loginLogoutViewModel.logInText,
                                isSignedIn: props.loginLogoutViewModel.isLoggedIn,
                                serverErrorText: props.loginLogoutViewModel.serverErrorText,
                                returnUrl: props.loginLogoutViewModel.returnUrl,
                            }}
                        />
                    )}
                    {!ticketSoldOut && !ticketNotExists && !ticketExpired && setupError && <ErrorMessage text={props.ticketSummaryProps.errorMessageText}></ErrorMessage>}
                </div>
                {isMember && showAmountSelector && (
                    <div className="TicketPage__wrapper">
                        <TicketAmountSelector
                            ticketData={ticketData}
                            setTicketData={setTicketData}
                            editorialProps={{
                                ...props.ticketAmountSelectorProps,
                                buyMoreTicketsNoButtonText: props.ticketMemberProps.buyMoreTicketsNoButtonText,
                                buyMoreTicketsText: props.ticketMemberProps.buyMoreTicketsText,
                                buyMoreTicketsYesButtonText: props.ticketMemberProps.buyMoreTicketsYesButtonText,
                                maxTotalTickets: props.maxTotalTickets,
                                maxTotalTicketsExceededError: props.maxTotalTicketsExceededError,
                                loginLinkText: props.loginLinkText,
                                logoutLinkText: props.logoutLinkText,
                            }}
                            setDatePickerIsOpen={setDatePickerIsOpen}
                            setIsExpress={setIsExpress}
                            setSelectedTime={setSelectedTime}
                            setSelectedDate={setSelectedDate}
                            numberOfTickets={numberOfTickets}
                            isMember={isMember}
                            availableTimeSlots={availableTimeSlots}
                            normalTicketId={normalTicketId}
                            isLoading={ticketAmountLoading}
                            isSoldOut={ticketSoldOut}
                            soldOutText={props.ticketTimeSlotSelectorProps.soldOutText}
                            isExpired={ticketExpired}
                            expiredText={props.ticketSummaryProps.ticketExpiredText}
                            notExists={ticketNotExists}
                            notExistsText={props.ticketSummaryProps.ticketNotExistsText}
                            freeText={props.freeText}
                            noDateAndScheduleRequired={noDateAndScheduleRequired}
                            setShowSummary={setShowSummary}
                            availableDates={availableDates}
                            signLink={{
                                signInUrl: props.loginLogoutViewModel.logInUrl,
                                signOutUrl: props.loginLogoutViewModel.logOutUrl,
                                signOutLabel: props.loginLogoutViewModel.logOutText,
                                signInLabel: props.loginLogoutViewModel.logInText,
                                isSignedIn: props.loginLogoutViewModel.isLoggedIn,
                                serverErrorText: props.loginLogoutViewModel.serverErrorText,
                                returnUrl: props.loginLogoutViewModel.returnUrl,
                            }}
                        />
                    </div>
                )}
                {!noDateAndScheduleRequired && datePickerIsOpen && (
                    <TicketDatePicker
                        availableDates={availableDates}
                        allAvailableDates={allAvailableDates}
                        editorialProps={...props.ticketDatePickerProps}
                        editorialTimeSlotProps={{
                            ...props.ticketTimeSlotSelectorProps,
                            title: props.ticketTimeSlotSelectorProps.timeSlotsTitle,
                            ingress: props.ticketTimeSlotSelectorProps.timeSlotsIngress,
                            showTimeslots: props.showTimeslots,
                        }}
                        todayLable={props.todayLable}
                        setSelectedTime={setSelectedTime}
                        selectedTime={selectedTime}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        isExpress={isExpress}
                        availableTimeSlots={availableTimeSlots}
                        ticketData={ticketData}
                        accessToken={accessToken}
                        datesError={datesError}
                        schedulesError={schedulesError}
                        noTimeslotsError={noTimeslotsError}
                        errorMessage={props.ticketSummaryProps.errorMessageText}
                        noTimeslotsErrorMessage={props.ticketTimeSlotSelectorProps.noTimeSlotsErrorMessage}
                        isLoading={dateLoading}
                        timeSlotLoading={timeSlotLoading}
                        setShowSummary={setShowSummary}
                        language={props.language}
                    />
                )}
                {showSummary && (
                    <TicketSummary
                        editorialProps={{ ...props.ticketSummaryProps, showTimeslots: props.showTimeslots }}
                        ticketData={ticketData}
                        totalAmountText={...props.ticketAmountSelectorProps.totalAmountText}
                        currencyText={props.ticketAmountSelectorProps.currencyText}
                        selectedDate={selectedDate}
                        selectedTime={selectedTime}
                        accessToken={accessToken}
                        isMember={props.isMember}
                        endpointCartMine={props.ticketMemberProps?.endpointCartMine}
                        isGiftMembership={isGiftMembership}
                        endpointValidation={props.endpointValidation}
                        validationErrorMessage={props.validationErrorMessage}
                        endpointCartMineClear={props.endpointCartMineClear}
                    />
                )}
            </div>
        </div>
    );
};
