import React, { useState, useEffect, useRef, FormEvent, Dispatch, SetStateAction } from "react";
import { CheckoutProps } from "./CheckoutProps.csharp";
import { initCart } from "../../../Pages/Ecommerce/Shared/CartApi/initCart";
import { CartItems } from "../Shared/CartItems/CartItems";
import { AddDiscounts } from "./AddDiscounts/AddDiscounts";
import { CartTotals } from "./CartTotals/CartTotals";
import ShippingOptions from "../Shared/ShippingOptions/ShippingOptions";
import { EditPersonalInfo } from "../Shared/EditPersonalInfo/EditPersonalInfo";
import { getData } from "../Checkout/api/get";
import { postRequest } from "../Checkout/api/post";
import { Loader } from "../Shared/Loader";
import { ErrorMessage } from "../Shared/ErrorMessage";
import { IRadio, RadioList } from "../Shared/RadioList/RadioList";
import { updatePayment } from "../Shared/api/updatePayment";
import { initDiscounts } from "../Shared/api/initDiscounts";

import cn from "classnames";

export const CheckoutPage = (props: CheckoutProps) => {
    // Summary with discounts
    const [cartId, setCartId] = useState(props.cartViewModel.cartId);
    const [cart, setCart] = useState([]);
    const [tickets, setTickets] = useState([]);

    const [discount, setDiscount] = useState({ label: "", value: "" });
    const [currentDiscount, setCurrentDiscount] = useState<string>();

    const [voucher, setVoucher] = useState({ label: "", value: "" });
    const [currentVoucher, setCurrentVoucher] = useState<string>();

    const [discountCoupon, setDiscountCoupon] = useState({ label: "", value: "" });
    const [currentDiscountCoupon, setCurrentDiscountCoupon] = useState<string>();

    const [grandTotal, setGrandTotal] = useState<string | number>();
    const [subTotal, setSubTotal] = useState<string | number>();
    const [summaryIsLoading, setSummaryIsLoading] = useState(false);
    const [showShippingOptions, setShowShippingOptions] = useState(false);

    // Personal info form
    const [memberData, setMemberData] = useState({
        countryId: "",
        city: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        postalCode: "",
        streetAddress: "",
    });
    const [isAddressLoading, setIsAddressLoading] = useState(false);
    const [newsletterIsChecked, setNewsletterIsChecked] = useState(false);

    //Shipping
    const [shippingMethod, setShippingMethod] = useState("");
    const [setupError, setSetupError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [skipShipping, setSkipShipping] = useState(false);
    const onlyTicketsInCart =
        tickets.length !== 0 &&
        cart.length === 0 &&
        !tickets.some((item) => item.product_type === "membership" || item.product_type === "membership_renew");

    // Payment
    const [summaryTotals, setSummaryTotals] = useState({ subTotal: 0, shipping: 0, total: 0, tax: 0 });
    const [paymentMethodsList, setPaymentMethodsList] = useState<IRadio[]>([]);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [showCheckoutError, setShowCheckoutError] = useState(false);
    const [showPaymentMethodsError, setShowPaymentMethodsError] = useState(false);
    const [paymentMethodFree, setPaymentMethodFree] = useState(false);

    const ref = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (ref?.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [ref.current, shippingMethod, skipShipping]);

    // Get cart items
    useEffect(() => {
        initCart(
            props.currentLanguage,
            props.cartViewModel.accessToken,
            props.endpointGraphQl,
            props.cartViewModel.cartId,
            setCartId,
            props.cartViewModel.addCartIdEndpoint,
            props.cartViewModel.endpointGuestCartNoId,
            props.cartViewModel.endpointMemberCartItems,
            setTickets,
            setCart,
            setSummaryIsLoading
        );
    }, []);

    // Get discounts
    const paymentInfoGuestEndpoint = props.endpointGetPaymentInformation;
    const paymentInfoMemberEndpoint = props.endpointMemberGetPaymentInformation;
    const guestHeaders = {
        "content-type": "application/json",
    };

    const memberHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${props.cartViewModel.accessToken}`,
    };

    const options = {
        method: "GET",
        headers: props.cartViewModel.accessToken ? memberHeaders : guestHeaders,
    };

    const grandTotalWithoutShipping = (data) => {
        const grandTotalWithoutShipping = data.totals.total_segments.find((item) => item.code === "grand_total").value;
        const shipping = data.totals.base_shipping_incl_tax;
        const total = parseInt(grandTotalWithoutShipping) - parseInt(shipping);
        setGrandTotal(total);
    };

    useEffect(() => {
        initDiscounts(
            props.cartViewModel.accessToken,
            paymentInfoGuestEndpoint,
            paymentInfoMemberEndpoint,
            options,
            setCurrentDiscount,
            setDiscount,
            discount,
            setCurrentVoucher,
            setVoucher,
            voucher,
            setCurrentDiscountCoupon,
            setDiscountCoupon,
            discountCoupon,
            setSubTotal,
            grandTotalWithoutShipping
        );
    }, [tickets, cart]);

    // Personal info form
    const updatePersonalInfo = async (data) => {
        setMemberData({
            countryId: memberData.countryId,
            city: data.city,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            postalCode: data.postalCode,
            streetAddress: data.streetAddress,
        });

        const setGetPersonalInfo = {
            address: {
                city: data.city,
                firstname: data.firstName,
                lastname: data.lastName,
                email: data.email,
                telephone: data.phoneNumber,
                postcode: data.postalCode,
                street: [data.streetAddress],
                country_id: memberData.countryId,
            },
            useForShipping: true,
        };
        setIsAddressLoading(true);
        try {
            const responseInitGetPersonalInfo = await postRequest(
                props.cartViewModel.accessToken
                    ? props.customerInformation.endpointSetAddressMember
                    : props.customerInformation.endpointSetAddress,
                JSON.stringify(setGetPersonalInfo),
                props.cartViewModel.accessToken
            );

            setIsAddressLoading(false);
            if (responseInitGetPersonalInfo.status === 200) {
                setShowShippingOptions(true);
            } else {
                setSetupError(true);
            }
        } catch (error) {
            setIsAddressLoading(false);
            setSetupError(true);
            console.log(error);
        }
    };

    const getPersonalInfo = () => {
        (async () => {
            try {
                setIsLoading(true);

                const data = await getData(
                    props.cartViewModel.accessToken ? props.endpointGetCartMember : props.cartViewModel.getCartEndpoint,
                    props.cartViewModel.accessToken,
                    setSetupError
                );

                if (data && data.billing_address) {
                    setMemberData({
                        countryId: data.billing_address.country_id ? data.billing_address.country_id : "NO",
                        city: data.billing_address.city ? data.billing_address.city : "",
                        firstName: data.billing_address.firstname ? data.billing_address.firstname : "",
                        lastName: data.billing_address.lastname ? data.billing_address.lastname : "",
                        email: data.billing_address.email ? data.billing_address.email : "",
                        phoneNumber: data.billing_address.telephone ? data.billing_address.telephone : "",
                        postalCode: data.billing_address.postcode ? data.billing_address.postcode : "",
                        streetAddress: data.billing_address.street[0] ? data.billing_address.street[0] : "",
                    });
                }

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setSetupError(true);
            }
        })();
    };

    const handleFormSubmit = (data) => {
        updatePersonalInfo(data);
    };
    useEffect(() => {
        getPersonalInfo();
    }, []);

    // Payment
    const getPaymentMethods = async () => {
        const timeout = setTimeout(() => setPaymentMethodLoading(true), 300);
        try {
            let cartId = props.cartViewModel.cartId;
            const endpoint = props.endpointGraphQl;
            if (props.cartViewModel.accessToken) {
                const queryCustomerCartId = JSON.stringify({
                    query: `{
                        customerCart {
                            id
                        }
                    }
                `,
                });

                const responseGetCustomerCartId = await postRequest(
                    endpoint,
                    queryCustomerCartId,
                    props.cartViewModel.accessToken
                );

                const dataGetCustomerCartId = await responseGetCustomerCartId.json();
                if (!responseGetCustomerCartId.ok) throw new Error(dataGetCustomerCartId);

                if (dataGetCustomerCartId.data != null) {
                    cartId = dataGetCustomerCartId.data.customerCart.id;
                    setCartId(dataGetCustomerCartId.data.customerCart.id);
                }
            }

            const headersNets = new Headers();
            var bearer = "Bearer " + props.cartViewModel.accessToken;
            headersNets.append("Accept", "application/json");
            headersNets.append("Content-Type", "application/json");
            props.cartViewModel.accessToken && headersNets.append("Authorization", bearer);
            const requestOptions: RequestInit = {
                method: "GET",
                headers: headersNets,
                redirect: "follow",
            };

            var responseGetPaymentInformation = null;
            await fetch(
                props.cartViewModel.accessToken
                    ? props.endpointMemberGetPaymentInformation
                    : props.endpointGetPaymentInformation,
                requestOptions
            )
                .then((response) => {
                    if (response.ok) return response.json();
                })
                .then((result) => {
                    responseGetPaymentInformation = result;
                })
                .catch((error) => {
                    console.log(error);
                });

            if (responseGetPaymentInformation === null || responseGetPaymentInformation === undefined)
                throw new Error(responseGetPaymentInformation);

            if (responseGetPaymentInformation?.payment_methods) {
                const paymentMethodFree =
                    responseGetPaymentInformation.payment_methods.find((item) => item.code === "free") &&
                    summaryTotals.total === 0;

                if (paymentMethodFree) {
                    setPaymentMethodFree(true);
                    setPaymentMethodsList([]);
                } else {
                    setPaymentMethodFree(false);
                    setPaymentMethodsList(
                        responseGetPaymentInformation.payment_methods.map((method) => ({
                            ...method,
                        }))
                    );
                }
            }
        } catch (e) {
            console.log(e);
            setShowPaymentMethodsError(true);
        } finally {
            clearLoader(timeout, setPaymentMethodLoading);
        }
    };

    const clearLoader = (timeout, setShowLoader: Dispatch<SetStateAction<boolean>>) => {
        clearTimeout(timeout);
        setShowLoader(false);
    };

    const doPayment = async () => {
        const timeout = setTimeout(() => setCheckoutLoading(true), 300);
        try {
            const endpoint = props.endpointGraphQl;
            if (paymentMethodFree) {
                const jsonSetPaymentMethodFree = JSON.stringify({
                    email: memberData.email,
                });
                const responseSetPaymentMethodFree = await postRequest(
                    props.setPaymentMethodFreeEndpoint,
                    jsonSetPaymentMethodFree,
                    null
                );
                const dataSetPaymentMethodFree = await responseSetPaymentMethodFree.json();
                if (!responseSetPaymentMethodFree.ok) throw new Error(dataSetPaymentMethodFree);

                if (dataSetPaymentMethodFree != null && dataSetPaymentMethodFree.url != null) {
                    window.location.href = dataSetPaymentMethodFree.url;
                } else {
                    setShowPaymentMethodsError(true);
                }
            } else {
                var endpointSetPaymentInformation = null;

                if (props.cartViewModel.accessToken) {
                    endpointSetPaymentInformation = props.endpointSetPaymentInformationMember;
                } else {
                    endpointSetPaymentInformation = props.endpointSetPaymentInformation;
                }

                updatePayment(
                    endpoint,
                    memberData.firstName,
                    memberData.lastName,
                    memberData.phoneNumber,
                    memberData.countryId,
                    [memberData.streetAddress],
                    memberData.city,
                    memberData.postalCode,
                    props.cartViewModel.cartId,
                    props.cartViewModel.vippsCartId,
                    memberData.email,
                    setShowPaymentMethodsError,
                    props.summary.vippsCallbackUrl,
                    () => {
                        clearLoader(timeout, setCheckoutLoading);
                    },
                    paymentMethod,
                    "1",
                    endpointSetPaymentInformation,
                    props.summary.endpointGetNetsUrl,
                    props.endpointVippsInitPayment,
                    props.cartViewModel.accessToken,
                    newsletterIsChecked
                );
            }
        } catch (e) {
            console.log(e);
            clearLoader(timeout, setCheckoutLoading);
            setShowCheckoutError(true);
        }
    };

    useEffect(() => {
        getPaymentMethods();
    }, [shippingMethod, skipShipping, summaryTotals.total]);

    return (
        <div className="PaymentPage">
            <div className="PaymentPage__Container">
                <section className="PaymentPage__Box">
                    <h2>{props.title}</h2>
                    {!summaryIsLoading ? (
                        <>
                            <CartItems
                                cartId={cartId}
                                sizeLabel={props.cartViewModel.sizeLabel}
                                errorMessage={props.cartViewModel.errorMessage}
                                products={cart}
                                graphQlEndpoint={props.endpointGraphQl}
                                setCart={setCart}
                                tickets={tickets}
                                setTickets={setTickets}
                                endpointGuestCartItems={props.cartViewModel.endpointGuestCartItems}
                                endpointMemberCartItems={props.cartViewModel.endpointMemberCartItems}
                                accessToken={props.cartViewModel.accessToken}
                                isCheckout={true}
                                currencyFormat={props.cartViewModel.currencyFormat}
                                currentLanguage={props.currentLanguage}
                            />

                            <CartTotals
                                discount={discount}
                                discountCoupon={discountCoupon}
                                voucher={voucher}
                                subTotal={subTotal}
                                grandTotal={grandTotal}
                                subTotalLabel={props.summary.subTotalText}
                                totalLabel={props.summary.totalText}
                                currencyFormat={props.cartViewModel.currencyFormat}
                            />
                            <AddDiscounts
                                props={props.discountCode}
                                setDiscount={setDiscount}
                                setCurrentDiscount={setCurrentDiscount}
                                discount={discount}
                                currentDiscount={currentDiscount}
                                setSubTotal={setSubTotal}
                                paymentInfoGuestEndpoint={paymentInfoGuestEndpoint}
                                paymentInfoMemberEndpoint={paymentInfoMemberEndpoint}
                                options={options}
                                memberHeaders={memberHeaders}
                                guestHeaders={guestHeaders}
                                accessToken={props.cartViewModel.accessToken}
                                setCurrentVoucher={setCurrentVoucher}
                                currentVoucher={currentVoucher}
                                setVoucher={setVoucher}
                                voucher={voucher}
                                grandTotalWithoutShipping={grandTotalWithoutShipping}
                                setCurrentDiscountCoupon={setCurrentDiscountCoupon}
                                currentDiscountCoupon={currentDiscountCoupon}
                                setDiscountCoupon={setDiscountCoupon}
                                discountCoupon={discountCoupon}
                            />
                        </>
                    ) : (
                        <Loader />
                    )}
                </section>

                <section className="PaymentPage__Box">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <EditPersonalInfo
                            className="EditPersonalInfo--in-checkout"
                            {...props.customerInformation}
                            memberData={memberData}
                            isLoading={isAddressLoading || paymentMethodLoading}
                            onFormSubmit={handleFormSubmit}
                            serverErrorMessage={props.billingAddressServerError}
                            hideNewsletterText={props.summary.hideNewsletterText}
                            newsletterSignup={{
                                label: props.summary.newsletterText,
                                isChecked: newsletterIsChecked,
                                setIsChecked: setNewsletterIsChecked,
                            }}
                            onlyTicketsInCart={onlyTicketsInCart}
                            isCheckout
                        />
                    )}
                    {setupError && <ErrorMessage text={props.title} />}
                </section>

                {showShippingOptions && (
                    <ShippingOptions
                        title={props.delivery.title}
                        endpoint={
                            props.cartViewModel.accessToken ? props.delivery.endpointMember : props.delivery.endpoint
                        }
                        setShippingEndpoint={
                            props.cartViewModel.accessToken
                                ? props.endpointSetShippingInformationMember
                                : props.endpointSetShippingInformation
                        }
                        token={props.token}
                        freePriceText={props.freePriceText}
                        currencyFormat={props.currencyFormat}
                        onShippingSelect={setShippingMethod}
                        memberData={memberData}
                        summaryTotals={summaryTotals}
                        setSummaryTotals={setSummaryTotals}
                        errorMessage={props.shippingMethodsServerError}
                        grandTotal={grandTotal}
                        onlyTicketsInCart={onlyTicketsInCart}
                        setSkipShipping={setSkipShipping}
                    />
                )}
                {(shippingMethod !== "" || skipShipping) && (
                    <section ref={ref} className="PaymentPage__Box">
                        <h2>{props.summary.title}</h2>
                        <CartTotals
                            grandTotal={summaryTotals.total}
                            shipping={summaryTotals.shipping}
                            subTotal={summaryTotals.subTotal}
                            tax={summaryTotals.tax}
                            subTotalLabel={props.summary.subTotalText}
                            totalLabel={props.summary.totalText}
                            shippingLabel={props.summary.shippingText}
                            taxText={props.summary.taxText}
                            currencyFormat={props.cartViewModel.currencyFormat}
                        />
                        {showPaymentMethodsError && <ErrorMessage text={props.summary.paymentMethodsErrorText} />}
                        {paymentMethodsList.length > 0 && (
                            <RadioList
                                radioList={paymentMethodsList}
                                setValue={setPaymentMethod}
                                value={paymentMethod}
                            />
                        )}
                        {props.summary.termsText && (
                            <div
                                className="CheckoutPage__terms-text"
                                dangerouslySetInnerHTML={{ __html: props.summary.termsText }}
                            ></div>
                        )}
                        <div className="PaymentPage__Button__Container" ref={ref}>
                            <button
                                className={cn("button--primary button--forward ", {
                                    "button--red": paymentMethod || paymentMethodFree || onlyTicketsInCart,
                                })}
                                disabled={!paymentMethod && !paymentMethodFree && !onlyTicketsInCart || checkoutLoading}
                                onClick={doPayment}
                            >
                                {checkoutLoading ? (
                                    <Loader isSmall isWhite />
                                ) : grandTotal && grandTotal !== 0 && !checkoutLoading ? (
                                    `${props.summary.paymentButtonText} ${props.currencyFormat.replace(
                                        "price",
                                        grandTotal.toString()
                                    )}`
                                ) : (
                                    props.paymentFreeButtonText
                                )}
                            </button>
                        </div>

                        {showCheckoutError && <ErrorMessage text={props.summary.checkoutErrorText} />}
                    </section>
                )}
            </div>
        </div>
    );
};
