import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/nb";
import TwoColumnsBlock from "../../Shared/TwoColumnsBlock/TwoColumnsBlock";

interface IMembershipRenewal {
    title?: string;
    intro?: string;
    linkItem?: {
        text?: string;
        url?: string;
    };
    daysLimitForShowingRenewalOfMembership: number;
    expirationDate: Date;
}

const MembershipRenewal = ({
    title,
    intro,
    linkItem,
    daysLimitForShowingRenewalOfMembership,
    expirationDate,
}: IMembershipRenewal) => {
    const [isSHown, setIsShown] = useState(false);

    const calculateDaysUntil = (date) => {
        const futureDate = moment(date, "YYYY-MM-DD");
        const today = moment();
        const diff = futureDate.diff(today, "days");
        return diff;
    };

    useEffect(() => {
        setIsShown(calculateDaysUntil(expirationDate) < daysLimitForShowingRenewalOfMembership);
    }, [expirationDate]);

    return isSHown ? (
        <TwoColumnsBlock title={title} intro={intro} link={linkItem} inOneCoulmn={true} />
    ) : null;
};

export default MembershipRenewal;
