import React from "react";
import { useEffect, useRef } from "react";

import { Loader } from "../../Shared/Loader";
import { RadioList } from "../../Shared/RadioList/RadioList";
import { ErrorMessage } from "../../Shared/ErrorMessage";
import { AddDiscounts } from "../../Checkout/AddDiscounts/AddDiscounts";
import classNames from "classnames";

interface PaymentProps {
    paymentMethodsList: any;
    title: string;
    setPaymentMethod: any;
    paymentMethod: any;
    doPayment: any;
    checkoutLoading: any;
    confirmPaymentButtonText: any;
    showCheckoutError: boolean;
    errorText: string;
    props: {
        linkText: string;
        endpointDiscountCodeGuest: string;
        endpointDiscountCodeMember: string;
        endpointVoucherCodeGuest: string;
        endpointVoucherCodeMember: string;
        discountCodePlaceholder: string;
        addDiscountCodeButtonText: string;
        removeDiscountCodeButtonText: string;
        discountCodeEmptyFieldValidationText: string;
        discountCodeErrorMessage: string;
        endpointVoucherClearGuest: string;
        endpointVoucherClearMember: string;
        endpointClearDiscountCouponsGuest: string;
        endpointClearDiscountCouponsMember: string;
    };
    setCurrentDiscount: React.Dispatch<React.SetStateAction<string>>;
    currentDiscount: string;
    setDiscount: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
    discount: {
        label: string;
        value: string;
    };
    setSubTotal: React.Dispatch<React.SetStateAction<string | number>>;
    subTotal?: string | number;
    paymentInfoGuestEndpoint: string;
    paymentInfoMemberEndpoint: string;
    options: any;
    memberHeaders?: any;
    guestHeaders: any;
    accessToken?: string;
    setCurrentVoucher: React.Dispatch<React.SetStateAction<string>>;
    currentVoucher: string;
    setVoucher: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
    voucher: {
        label: string;
        value: string;
    };
    setCurrentDiscountCoupon: React.Dispatch<React.SetStateAction<string>>;
    currentDiscountCoupon: string;
    setDiscountCoupon: React.Dispatch<React.SetStateAction<{ label: string; value: string }>>;
    discountCoupon: {
        label: string;
        value: string;
    };
    grandTotalWithoutShipping: (data: any) => void;

    grandTotal?: string | number;
    paymentMethodFree: boolean;
    showPayment: boolean;
    currencyFormat: string;
    paymentFreeButtonText: string;
}

export const Payment = ({
    paymentMethodsList,
    title,
    setPaymentMethod,
    paymentMethod,
    doPayment,
    checkoutLoading,
    confirmPaymentButtonText,
    showCheckoutError,
    errorText,
    props,
    setDiscount,
    setCurrentDiscount,
    discount,
    currentDiscount,
    setSubTotal,
    paymentInfoGuestEndpoint,
    paymentInfoMemberEndpoint,
    options,
    memberHeaders,
    guestHeaders,
    accessToken,
    setCurrentVoucher,
    currentVoucher,
    setVoucher,
    voucher,
    setCurrentDiscountCoupon,
    currentDiscountCoupon,
    setDiscountCoupon,
    discountCoupon,
    grandTotalWithoutShipping,
    subTotal,
    grandTotal,
    paymentMethodFree,
    showPayment,
    currencyFormat,
    paymentFreeButtonText,
}: PaymentProps) => {
    const paymentMethodRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        if (paymentMethodRef?.current && showPayment) {
            setTimeout(() => paymentMethodRef.current.scrollIntoView({ behavior: "smooth", block: "end" }), 300);
            setTimeout(() => paymentMethodRef.current.classList.add("animateIn"), 750);
        }
    }, [paymentMethodsList]);

    return (
        <section className="TicketPage__card" ref={paymentMethodRef}>
            <h2 className="PaymentPage__title">{title}</h2>
            <div className="Payment__discounts">
                <AddDiscounts
                    props={props}
                    setDiscount={setDiscount}
                    setCurrentDiscount={setCurrentDiscount}
                    discount={discount}
                    currentDiscount={currentDiscount}
                    setSubTotal={setSubTotal}
                    paymentInfoGuestEndpoint={paymentInfoGuestEndpoint}
                    paymentInfoMemberEndpoint={paymentInfoMemberEndpoint}
                    options={options}
                    memberHeaders={memberHeaders}
                    guestHeaders={guestHeaders}
                    accessToken={accessToken}
                    setCurrentVoucher={setCurrentVoucher}
                    currentVoucher={currentVoucher}
                    setVoucher={setVoucher}
                    voucher={voucher}
                    grandTotalWithoutShipping={grandTotalWithoutShipping}
                    subTotal={subTotal}
                    grandTotal={grandTotal}
                    currencyFormat={currencyFormat}
                    setCurrentDiscountCoupon={setCurrentDiscountCoupon}
                    currentDiscountCoupon={currentDiscountCoupon}
                    setDiscountCoupon={setDiscountCoupon}
                    discountCoupon={discountCoupon}
                />
            </div>
            {paymentMethodsList.length > 0 && (
                <div className="Payment__radio-list">
                    <RadioList radioList={paymentMethodsList} setValue={setPaymentMethod} value={paymentMethod} />
                </div>
            )}
            <div className="PaymentPage__Button__Container">
                <button
                    className={classNames("button--primary button--forward ", {
                        "button--red": paymentMethod || paymentMethodFree,
                    })}
                    onClick={doPayment}
                    disabled={!paymentMethod && !paymentMethodFree || checkoutLoading}
                >
                    {checkoutLoading ? (
                        <Loader isSmall isWhite />
                    ) : grandTotal && grandTotal !== 0 && !checkoutLoading ? (
                        `${confirmPaymentButtonText} ${currencyFormat.replace("price", grandTotal.toString())}`
                    ) : (
                        paymentFreeButtonText
                    )}
                </button>
            </div>

            {showCheckoutError && (
                <div className="Payment__error">
                    <ErrorMessage text={errorText} />
                </div>
            )}
        </section>
    );
};
