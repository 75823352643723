import { CountryCode } from "libphonenumber-js";
import React, { useState, Dispatch, SetStateAction, useRef, useEffect } from "react";
import { CreateMemberProps } from "./CreateMemberProps.csharp";
import { checkEmailIsValid } from "../Shared/utils/checkEmailIsValid";
import { Form } from "../Shared/Form/Form";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import { postRequest } from "../Shared/api/post";
import { updatePayment } from "../Shared/api/updatePayment";
import { IRadio } from "../Shared/RadioList/RadioList";
import { initDiscounts } from "../Shared/api/initDiscounts";
import { Payment } from "../Shared/Payment/Payment";

export const CreateMemberPage = (props: CreateMemberProps) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState<CountryCode>("NO");
    const [isLoading, setIsLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);
    const [firstNameIsValid, setFirstNameIsValid] = useState(false);
    const [lastNameIsValid, setLastNameIsValid] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
    const [showFirstNameError, setShowFirstNameError] = useState(false);
    const [showLastNameError, setShowLastNameError] = useState(false);
    const [cartId, setCartId] = useState<string>();
    const [paymentMethodsList, setPaymentMethodsList] = useState<IRadio[]>([]);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [newsletterIsChecked, setNewsletterIsChecked] = useState(false);
    const ref = useRef<null | HTMLDivElement>(null);

    const [discount, setDiscount] = useState({ label: "", value: "" });
    const [currentDiscount, setCurrentDiscount] = useState<string>();
    const [voucher, setVoucher] = useState({ label: "", value: "" });
    const [currentVoucher, setCurrentVoucher] = useState<string>();
    const [discountCoupon, setDiscountCoupon] = useState({ label: "", value: "" });
    const [currentDiscountCoupon, setCurrentDiscountCoupon] = useState<string>();
    const [grandTotal, setGrandTotal] = useState<string | number>();
    const [subTotal, setSubTotal] = useState<string | number>();
    const [paymentMethodFree, setPaymentMethodFree] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [showCheckoutError, setShowCheckoutError] = useState(false);

    useEffect(() => {
        if (ref?.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [ref.current]);

    const ecommerce = {
        currency: "NOK",
        value: null,
        items: [
            {
                item_id: props.sku,
                item_name: "Medlemskap",
                price: null,
                quantity: 1
            }
        ]
    };
    
    useEffect(() => {
        // @ts-ignore
        const dataLayer = window.dataLayer || [];
        dataLayer.push({ ecommerce: null });

        dataLayer.push({
            event: "view_item",
            ecommerce: ecommerce
            }
        );

    }, []);


    useEffect(() => {
        if(showPayment){
            // @ts-ignore
            const dataLayer = window.dataLayer || [];

            dataLayer.push({
                event: "add_to_cart",
                ecommerce: ecommerce
                }
            );

            dataLayer.push({
                event: "begin_checkout",
                ecommerce: ecommerce
                }
            );
        }
    }, [showPayment]);



    const setNameError = (name: string, setIsNameValid: Dispatch<SetStateAction<boolean>>) => {
        const validCharactersRegex = /^[a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿ\- ]+$/;
        const isValid = validCharactersRegex.test(name);
        setIsNameValid(name !== "" && isValid);
    };

    const clearLoader = (timeout, setShowLoader: Dispatch<SetStateAction<boolean>>) => {
        clearTimeout(timeout);
        setShowLoader(false);
    };

    const getPaymentMethods = async (cartId) => {
        const timeout = setTimeout(() => setPaymentMethodLoading(true), 300);
        try {
            const headersNets = new Headers();

            headersNets.append("Accept", "application/json");
            headersNets.append("Content-Type", "application/json");

            if (props.accessToken) {
                var bearer = "Bearer " + props.accessToken;
                headersNets.append("Authorization", bearer);
            }

            const requestOptions: RequestInit = {
                method: "GET",
                headers: headersNets,
                redirect: "follow",
            };

            var responseGetPaymentInformation = null;
            await fetch(props.endpointGetPaymentInformation.replace("#cartId#", cartId), requestOptions)
                .then((response) => {
                    if (response.ok) return response.json();
                })
                .then((result) => {
                    responseGetPaymentInformation = result;
                })
                .catch((error) => {
                    console.log(error);
                });

            if (responseGetPaymentInformation === null || responseGetPaymentInformation === undefined)
                throw new Error(responseGetPaymentInformation);

            if (responseGetPaymentInformation?.payment_methods) {
                const paymentMethodFree = responseGetPaymentInformation.payment_methods.find(
                    (item) => item.code === "free" && grandTotal === 0
                );

                if (paymentMethodFree) {
                    setPaymentMethodFree(true);
                    setPaymentMethodsList([]);
                } else {
                    setPaymentMethodFree(false);
                    setPaymentMethodsList(
                        responseGetPaymentInformation.payment_methods.map((method) => ({
                            ...method,
                        }))
                    );
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            clearLoader(timeout, setPaymentMethodLoading);
        }
    };

    const doPayment = async () => {
        const timeout = setTimeout(() => setCheckoutLoading(true), 300);

        if (paymentMethodFree) {
            const jsonSetPaymentMethodFree = JSON.stringify({
                email: email,
            });
            const responseSetPaymentMethodFree = await postRequest(
                props.setPaymentMethodFreeEndpoint,
                jsonSetPaymentMethodFree,
                null
            );

            const dataSetPaymentMethodFree = await responseSetPaymentMethodFree.json();
            if (!responseSetPaymentMethodFree.ok) throw new Error(dataSetPaymentMethodFree);

            if (dataSetPaymentMethodFree != null && dataSetPaymentMethodFree.url != null) {
                window.location.href = dataSetPaymentMethodFree.url;
            } else {
                setShowErrorMessage(true);
            }
        } else {
            try {
                const endpoint = props.graphQlUrl;
                updatePayment(
                    endpoint,
                    firstName,
                    lastName,
                    phoneNumber,
                    countryCode,
                    [""],
                    null,
                    null,
                    cartId,
                    props.vippsCartId,
                    email,
                    setShowCheckoutError,
                    props.vippsCallbackUrl.replace("#cartId#", cartId),
                    () => {
                        clearLoader(timeout, setCheckoutLoading);
                    },
                    paymentMethod,
                    props.termsId,
                    props.endpointSetPaymentInformation.replace("#cartId#", cartId),
                    props.endpointGetNetsUrl,
                    props.endpointVippsInitPayment,
                    props.accessToken,
                    newsletterIsChecked
                );
            } catch (e) {
                console.log(e);
                setShowErrorMessage(true);
                clearLoader(timeout, setCheckoutLoading);
            }
        }
    };

    const toPayment = async () => {
        setShowErrorMessage(false);
        const timeout = setTimeout(() => {
            setIsLoading(true);
        }, 300);
        try {
            let newCartId = cartId;
            const createMemberEndpoint = props.cartEndpoint;
            var alreadyHasItemsInCart = false;

            if (!cartId) {
                if (props.accessToken) {
                    const response = await postRequest(props.endpointMemberCartInit, null, props.accessToken);
                    var cartMemberResponse = await response.json();
                    setCartId(cartMemberResponse);
                    newCartId = cartMemberResponse;
                } else {
                    const response = await postRequest(props.endpointGuestCartInit, null, null);
                    var cartGuestResponse = await response.json();
                    console.log(cartGuestResponse);
                    setCartId(cartGuestResponse);
                    newCartId = cartGuestResponse;
                }

                const addCartIdEndpoint = props.endpointAddCartId;
                const addCartId = JSON.stringify({
                    cartId: newCartId,
                });

                const addCartIdResponse = await postRequest(addCartIdEndpoint, addCartId, null);
                if (!addCartIdResponse.ok) throw new Error(addCartIdResponse.statusText);
            } else {
                if (props.mainCartUrl) {
                    const requestOptions: RequestInit = {
                        method: "GET",
                        redirect: "follow",
                    };

                    var guestCart = await (await fetch(props.endpointGetCart, requestOptions)).json();
                    if (guestCart && guestCart.items && guestCart.items.length > 0) {
                        alreadyHasItemsInCart = true;
                    }
                }
            }

            const reservationEndpoint = createMemberEndpoint.replace("#cartId#", newCartId);
            const data = JSON.stringify({
                cartItem: {
                    sku: props.sku,
                    qty: 1,
                    extension_attributes: {
                        membership_purchase: {
                            extension_attributes: {
                                new_membership: {
                                    id: null,
                                    auto_renew: true,
                                    members: [
                                        {
                                            member: {
                                                email: email,
                                                country: countryCode,
                                                first_name: firstName,
                                                last_name: lastName,
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    },
                },
            });
            const response = await postRequest(reservationEndpoint, data, props.accessToken);
            if (!response.ok) throw new Error(response.statusText);

            if (alreadyHasItemsInCart) {
                window.location.href = props.mainCartUrl;
            } else {
                getPaymentMethods(newCartId);
                initDiscounts(
                    null,
                    props.endpointGetPaymentInformation.replace("#cartId#", newCartId),
                    paymentInfoMemberEndpoint,
                    options,
                    setCurrentDiscount,
                    setDiscount,
                    discount,
                    setCurrentVoucher,
                    setVoucher,
                    voucher,
                    setCurrentDiscountCoupon,
                    setDiscountCoupon,
                    discountCoupon,
                    setSubTotal,
                    grandTotalWithoutShipping
                );
            }
            clearLoader(timeout, setIsLoading);
        } catch (e) {
            console.log(e);
            setShowErrorMessage(true);
            clearLoader(timeout, setIsLoading);
        }
    };

    const disabled =
        email === "" ||
        firstName === "" ||
        lastName === "" ||
        phoneNumber === "" ||
        !firstNameIsValid ||
        !lastNameIsValid ||
        !emailIsValid ||
        !phoneNumberIsValid;

    const inputs = [
        {
            type: "text" as const,
            label: props.firstNameLabel,
            isRequired: true,
            value: firstName,
            onBlur: () => {
                setShowFirstNameError(true);
            },
            isBig: false,
            autoComplete: "given-name",
            placeholder: props.firstNamePlaceholder,
            onChange: (v) => {
                setFirstName(v);
                setNameError(v, setFirstNameIsValid);
            },
            errorValidation: showFirstNameError && !firstNameIsValid,
            errorValidationText: "Mangler fornavn eller fornavn har ulovlig tegn",
            showError: showFirstNameError,
        },
        {
            type: "text" as const,
            label: props.lastNameLabel,
            isRequired: true,
            value: lastName,
            onBlur: () => {
                setShowLastNameError(true);
            },
            isBig: false,
            autoComplete: "family-name",
            placeholder: props.lastNamePlaceholder,
            onChange: (v) => {
                setLastName(v);
                setNameError(v, setLastNameIsValid);
            },
            errorValidation: showLastNameError && !lastNameIsValid,
            errorValidationText: "Mangler etternavn eller etternavn har ulovlig tegn",
            showError: showLastNameError,
        },
        {
            type: "email" as const,
            label: props.emailLabel,
            isRequired: true,
            value: email,
            onBlur: () => {
                setShowEmailError(true);
            },
            isBig: true,
            autoComplete: "On",
            placeholder: props.emailPlaceholder,
            onChange: (v) => {
                setEmail(v);
                setEmailIsValid(checkEmailIsValid(v));
            },
            errorValidation: showEmailError && !emailIsValid,
            errorValidationText: props.emailValidationText,
            showError: showEmailError,
        },
        {
            type: "tel" as const,
            label: props.phoneNumberLabel,
            isRequired: true,
            value: phoneNumber,
            onBlur: () => {
                setShowPhoneNumberError(true);
            },
            isBig: true,
            placeholder: props.phonePlaceholder,
            onChange: (v) => {
                setPhoneNumber(v);
                setPhoneNumberIsValid(isValidPhoneNumber(v ? v : "", countryCode));
            },
            autoComplete: "On",
            setCountryCode: setCountryCode,
            countryCode: countryCode,
            errorValidation: showPhoneNumberError && !phoneNumberIsValid,
            errorValidationText: "Ugyldig mobilnummer",
            showError: showPhoneNumberError,
        },
    ];

    useEffect(() => {
        cartId && getPaymentMethods(cartId);
    }, [grandTotal]);
    const paymentInfoGuestEndpoint = props.endpointGetPaymentInformation;
    const paymentInfoMemberEndpoint = props.endpointMemberGetPaymentInformation;

    const guestHeaders = {
        "content-type": "application/json",
    };

    const options = {
        method: "GET",
        headers: guestHeaders,
    };

    const grandTotalWithoutShipping = (data) => {
        const grandTotalWithoutShipping = data.totals.total_segments.find((item) => item.code === "grand_total").value;
        const shipping = data.totals.base_shipping_incl_tax;
        const total = parseInt(grandTotalWithoutShipping) - parseInt(shipping);
        setGrandTotal(total);
    };

    useEffect(() => {
        if (cartId) {
            initDiscounts(
                null,
                paymentInfoGuestEndpoint,
                paymentInfoMemberEndpoint,
                options,
                setCurrentDiscount,
                setDiscount,
                discount,
                setCurrentVoucher,
                setVoucher,
                voucher,
                setCurrentDiscountCoupon,
                setDiscountCoupon,
                discountCoupon,
                setSubTotal,
                grandTotalWithoutShipping
            );
        }
    }, []);

    const discountProps = {
        linkText: props.discountCode.linkText ? props.discountCode.linkText : "Rabatter",
        endpointDiscountCodeGuest: props.discountCode.endpointDiscountCodeGuest.replace(
            "guest-carts/",
            `guest-carts/${cartId}`
        ),
        endpointDiscountCodeMember: props.discountCode.endpointDiscountCodeMember,
        endpointVoucherCodeGuest: props.discountCode.endpointVoucherCodeGuest.replace(
            "guest-carts/",
            `guest-carts/${cartId}`
        ),
        endpointVoucherCodeMember: props.discountCode.endpointVoucherCodeMember,
        discountCodePlaceholder: props.discountCode.discountCodePlaceholder,
        addDiscountCodeButtonText: props.discountCode.addDiscountCodeButtonText,
        removeDiscountCodeButtonText: props.discountCode.removeDiscountCodeButtonText,
        discountCodeEmptyFieldValidationText: props.discountCode.discountCodeEmptyFieldValidationText,
        discountCodeErrorMessage: props.discountCode.discountCodeErrorMessage,
        endpointVoucherClearGuest: props.discountCode.endpointVoucherClearGuest.replace(
            "guest-carts/",
            `guest-carts/${cartId}`
        ),
        endpointVoucherClearMember: props.discountCode.endpointVoucherClearMember,
        endpointClearDiscountCouponsGuest: props.discountCode.endpointClearDiscountCouponsGuest,
        endpointClearDiscountCouponsMember: props.discountCode.endpointClearDiscountCouponsMember,
    };
    return (
        <div className="PaymentPage">
            <div className="PaymentPage__Container">
                <section className="TicketPage__card animateIn">
                    <h1 className="CreateMember__Title">{props.title}</h1>
                    <p className="CreateMember__Ingresstext">{props.intro}</p>
                    {props.body.length !== 0 && (
                        <ul className="CreateMember__BodyText list-checkmark">
                            {props.body.map((item) => (
                                <li className="CreateMember__BodyText-item" key={item}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    )}

                    <Form
                        inputs={inputs}
                        onSubmit={(e) => {
                            e.preventDefault();
                            setShowPayment(true);
                            toPayment();
                        }}
                        autoComplete={"on"}
                        confirmButtonText={props.confirmButtonText}
                        disabled={disabled}
                        isLoading={isLoading}
                        showErrorMessage={showErrorMessage}
                        errorMessage={props.errorText}
                        richText={props.termsText}
                        hideNewsletterText={props.hideNewsletterText}
                        newsletterSignup={{
                            label: props.newsletterSignupText,
                            isChecked: newsletterIsChecked,
                            setIsChecked: setNewsletterIsChecked,
                        }}
                    />
                </section>
                <Payment
                    showPayment={showPayment}
                    paymentMethodsList={paymentMethodsList}
                    title={props.choosePaymentMethodTitle}
                    setPaymentMethod={setPaymentMethod}
                    paymentMethod={paymentMethod}
                    doPayment={doPayment}
                    checkoutLoading={checkoutLoading || paymentMethodLoading}
                    confirmPaymentButtonText={props.confirmButtonText}
                    paymentFreeButtonText={props.paymentFreeButtonText}
                    showCheckoutError={showCheckoutError}
                    errorText={props.errorText}
                    props={discountProps}
                    setDiscount={setDiscount}
                    setCurrentDiscount={setCurrentDiscount}
                    discount={discount}
                    currentDiscount={currentDiscount}
                    setSubTotal={setSubTotal}
                    paymentInfoGuestEndpoint={props.endpointGetPaymentInformation.replace("#cartId#", cartId)}
                    paymentInfoMemberEndpoint={paymentInfoMemberEndpoint}
                    options={options}
                    guestHeaders={guestHeaders}
                    setCurrentVoucher={setCurrentVoucher}
                    currentVoucher={currentVoucher}
                    setVoucher={setVoucher}
                    voucher={voucher}
                    grandTotalWithoutShipping={grandTotalWithoutShipping}
                    grandTotal={grandTotal}
                    subTotal={subTotal}
                    paymentMethodFree={paymentMethodFree}
                    currencyFormat={props.currencyFormat}
                    setCurrentDiscountCoupon={setCurrentDiscountCoupon}
                    currentDiscountCoupon={currentDiscountCoupon}
                    setDiscountCoupon={setDiscountCoupon}
                    discountCoupon={discountCoupon}
                ></Payment>
            </div>
        </div>
    );
};
