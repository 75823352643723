import React, { useRef, useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import { PaymentProps } from "./PaymentProps.csharp";
import { checkEmailIsValid } from "../Shared/utils/checkEmailIsValid";
import { postRequest } from "../Shared/api/post";
import { Form } from "../Shared/Form/Form";
import { IRadio } from "../Shared/RadioList/RadioList";
import { updatePayment } from "../Shared/api/updatePayment";
import { Payment } from "../Shared/Payment/Payment";
import { initDiscounts } from "../Shared/api/initDiscounts";

export const PaymentPage = (props: PaymentProps) => {
    const [discount, setDiscount] = useState({ label: "", value: "" });
    const [currentDiscount, setCurrentDiscount] = useState<string>();
    const [voucher, setVoucher] = useState({ label: "", value: "" });
    const [currentVoucher, setCurrentVoucher] = useState<string>();
    const [discountCoupon, setDiscountCoupon] = useState({ label: "", value: "" });
    const [currentDiscountCoupon, setCurrentDiscountCoupon] = useState<string>();
    const [grandTotal, setGrandTotal] = useState<string | number>();
    const [subTotal, setSubTotal] = useState<string | number>();
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [showEmailErrorMessage, setShowEmailErrorMessage] = useState(false);
    const [paymentMethodFree, setPaymentMethodFree] = useState(false);
    const [emailInput, setEmailInput] = useState("");
    const [showFormErrorMessage, setShowFormErrorMessage] = useState(false);
    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
    const [checkoutLoading, setCheckoutLoading] = useState(false);
    const [cartId, setCartId] = useState(props.cartId);
    const [paymentMethodsList, setPaymentMethodsList] = useState<IRadio[]>([]);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [showCheckoutError, setShowCheckoutError] = useState(false);
    const [newsletterIsChecked, setNewsletterIsChecked] = useState(false);
    const ref = useRef<null | HTMLDivElement>(null);
    const [showPayment, setShowPayment] = useState(false);


    const [tickets, setTickets] = useState([]);
    const [currency, setCurrency] = useState<string>();


    useEffect(() => {
        if (ref?.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [ref.current]);

    const clearLoader = (timeout, setShowLoader: Dispatch<SetStateAction<boolean>>) => {
        clearTimeout(timeout);
        setShowLoader(false);
    };

    const getPaymentMethods = async () => {
        const timeout = setTimeout(() => setPaymentMethodLoading(true), 300);
        try {
            let cartId = props.cartId;
            const endpoint = props.graphQlUrl;
            if (props.accessToken) {
                const queryCustomerCartId = JSON.stringify({
                    query: `{
                        customerCart {
                            id
                        }
                    }
                `,
                });

                const responseGetCustomerCartId = await postRequest(endpoint, queryCustomerCartId, props.accessToken);

                const dataGetCustomerCartId = await responseGetCustomerCartId.json();
                if (!responseGetCustomerCartId.ok) throw new Error(dataGetCustomerCartId);

                if (dataGetCustomerCartId.data != null) {
                    cartId = dataGetCustomerCartId.data.customerCart.id;
                    setCartId(dataGetCustomerCartId.data.customerCart.id);
                }
            }

            const headersNets = new Headers();
            var bearer = "Bearer " + props.accessToken;
            headersNets.append("Accept", "application/json");
            headersNets.append("Content-Type", "application/json");

            props.accessToken && headersNets.append("Authorization", bearer);

            const requestOptions: RequestInit = {
                method: "GET",
                headers: headersNets,
                redirect: "follow",
            };

            var responseGetPaymentInformation = null;
            await fetch(
                props.accessToken ? props.endpointMemberGetPaymentInformation : props.endpointGetPaymentInformation,
                requestOptions
            )
                .then((response) => {
                    if (response.ok) return response.json();
                })
                .then((result) => {
                    responseGetPaymentInformation = result;
                })
                .catch((error) => {
                    console.log(error);
                });

            if (responseGetPaymentInformation === null || responseGetPaymentInformation === undefined)
                throw new Error(responseGetPaymentInformation);

            if (responseGetPaymentInformation?.payment_methods) {
                const paymentMethodFree =
                    responseGetPaymentInformation.payment_methods.find((item) => item.code === "free") ||
                    grandTotal === 0;
                if (paymentMethodFree) {
                    setPaymentMethodFree(true);
                    setPaymentMethodsList([]);
                } else {
                    setPaymentMethodFree(false);
                    setPaymentMethodsList(
                        responseGetPaymentInformation.payment_methods.map((method) => ({
                            ...method,
                        }))
                    );
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            clearLoader(timeout, setPaymentMethodLoading);
        }
    };

    const doPayment = async () => {
        setCheckoutLoading(true);

        if (paymentMethodFree) {
            const jsonSetPaymentMethodFree = JSON.stringify({
                email: emailInput,
            });
            const responseSetPaymentMethodFree = await postRequest(
                props.setPaymentMethodFreeEndpoint,
                jsonSetPaymentMethodFree,
                null
            );
            const dataSetPaymentMethodFree = await responseSetPaymentMethodFree.json();
            if (!responseSetPaymentMethodFree.ok) throw new Error(dataSetPaymentMethodFree);

            if (dataSetPaymentMethodFree != null && dataSetPaymentMethodFree.url != null) {
                window.location.href = dataSetPaymentMethodFree.url;
            } else {
                setShowCheckoutError(true);
            }
        } else {
            try {
                const endpoint = props.graphQlUrl;
                updatePayment(
                    endpoint,
                    null,
                    null,
                    null,
                    "NO",
                    [""],
                    null,
                    null,
                    props.cartId,
                    props.vippsCartId,
                    emailInput,
                    setShowFormErrorMessage,
                    props.vippsCallbackUrl,
                    () => {
                        setCheckoutLoading(false);
                    },
                    paymentMethod,
                    props.termsId,
                    props.endpointSetPaymentInformation,
                    props.endpointGetNetsUrl,
                    props.endpointVippsInitUrl,
                    props.accessToken,
                    newsletterIsChecked
                );
            } catch (e) {
                console.log(e);
                setCheckoutLoading(false);
                setShowCheckoutError(true);
            }
        }
    };

    const toPayment = async () => {
        try {
            getPaymentMethods();
        } catch (e) {
            console.log(e);
            setShowFormErrorMessage(true);
        }
    };

    const onSubmit = (event: FormEvent) => {
        event.preventDefault();
        setShowPayment(true);
        toPayment()
            // make sure to catch any error
            .catch(console.error);
    };

    if (props.email !== null && props.email !== undefined) {
        useEffect(() => {
            setEmailInput(props.email);
            setEmailIsValid(checkEmailIsValid(props.email));
            setShowEmailErrorMessage(true);
        }, []);
    }

    const paymentMethodRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        if (paymentMethodRef?.current && paymentMethodsList.length > 0) {
            setTimeout(() => paymentMethodRef.current.scrollIntoView({ behavior: "smooth", block: "end" }), 300);
            setTimeout(() => paymentMethodRef.current.classList.add("animateIn"), 750);
        }
    }, [paymentMethodsList]);

    useEffect(() => {
        getPaymentMethods();
    }, [grandTotal]);

    useEffect(() => {
       
        if(tickets && currency && grandTotal){
            // @ts-ignore
            const dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });
            dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                currency: currency,
                value: grandTotal,
                items: tickets
                }
            });
        }
    }, [tickets]);

    if (props.email !== null && props.email !== undefined) {
        useEffect(() => {
            setEmailInput(props.email);
            setEmailIsValid(checkEmailIsValid(props.email));
            setShowEmailErrorMessage(true);
        }, []);
    }

    // Get discounts
    const discountProps = {
        linkText: props.discountCode.linkText ? props.discountCode.linkText : "Rabatter",
        endpointDiscountCodeGuest: props.discountCode.endpointDiscountCodeGuest,
        endpointDiscountCodeMember: props.discountCode.endpointDiscountCodeMember,
        endpointVoucherCodeGuest: props.discountCode.endpointVoucherCodeGuest,
        endpointVoucherCodeMember: props.discountCode.endpointVoucherCodeMember,
        discountCodePlaceholder: props.discountCode.discountCodePlaceholder,
        addDiscountCodeButtonText: props.discountCode.addDiscountCodeButtonText,
        removeDiscountCodeButtonText: props.discountCode.removeDiscountCodeButtonText,
        discountCodeEmptyFieldValidationText: props.discountCode.discountCodeEmptyFieldValidationText,
        discountCodeErrorMessage: props.discountCode.discountCodeErrorMessage,
        endpointVoucherClearGuest: props.discountCode.endpointVoucherClearGuest,
        endpointVoucherClearMember: props.discountCode.endpointVoucherClearMember,
        endpointClearDiscountCouponsGuest: props.discountCode.endpointClearDiscountCouponsGuest,
        endpointClearDiscountCouponsMember: props.discountCode.endpointClearDiscountCouponsMember,
    };

    const paymentInfoGuestEndpoint = props.endpointGetPaymentInformation;
    const paymentInfoMemberEndpoint = props.endpointMemberGetPaymentInformation;
    const guestHeaders = {
        "content-type": "application/json",
    };

    const memberHeaders = {
        "content-type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
    };

    const options = {
        method: "GET",
        headers: props.accessToken ? memberHeaders : guestHeaders,
    };

    const convertItems = items => {
        return items.map(item => ({
            item_id: item.item_id,
            item_name: item.name,
            price: item.price,
            quantity: item.qty
        }));
    };

    const grandTotalWithoutShipping = (data) => {
        const grandTotalWithoutShipping = data.totals.total_segments.find((item) => item.code === "grand_total").value;
        const shipping = data.totals.base_shipping_incl_tax;
        const total = parseInt(grandTotalWithoutShipping) - parseInt(shipping);
        setGrandTotal(total);
        setCurrency(data.totals.base_currency_code);
        setTickets(convertItems(data.totals.items));
    };

    useEffect(() => {
        initDiscounts(
            props.accessToken,
            paymentInfoGuestEndpoint,
            paymentInfoMemberEndpoint,
            options,
            setCurrentDiscount,
            setDiscount,
            discount,
            setCurrentVoucher,
            setVoucher,
            voucher,
            setCurrentDiscountCoupon,
            setDiscountCoupon,
            discountCoupon,
            setSubTotal,
            grandTotalWithoutShipping
        );
    }, []);

    const disabled = emailInput === "" || !emailIsValid;

    const isEmailValid = (v) => {
        setEmailIsValid(checkEmailIsValid(v));
        setShowEmailErrorMessage(true);
    };

    const inputs = [
        {
            type: "email" as const,
            label: props.emailLabel,
            isRequired: true,
            value: emailInput,
            onBlur: () => {
                setShowEmailErrorMessage(true);
            },
            isBig: true,
            autoComplete: "On",
            placeholder: props.emailPlaceholder,
            onChange: (v) => {
                setEmailInput(v);
                isEmailValid(v);
            },
            errorValidation: showEmailErrorMessage && !emailIsValid,
            errorValidationText: props.emailValidationText,
            showError: showEmailErrorMessage,
        },
    ];

    return (
        <div className="PaymentPage">
            <div className="PaymentPage__Container">
                <section className="TicketPage__card animateIn">
                    <h1 className="PaymentPage__title">{(grandTotal === 0) ? props.freeTicketHeadingText : props.title}</h1>
                    <Form
                        inputs={inputs}
                        onSubmit={(e) => onSubmit(e)}
                        autoComplete={"on"}
                        confirmButtonText={(grandTotal === 0) ? props.freeTicketButtonText : props.confirmButtonText}
                        disabled={disabled}
                        isLoading={paymentMethodLoading}
                        showErrorMessage={showFormErrorMessage}
                        errorMessage={props.errorText}
                        hideNewsletterText={props.hideNewsletterText}
                        newsletterSignup={{
                            label: props.newsletterSignupText,
                            isChecked: newsletterIsChecked,
                            setIsChecked: setNewsletterIsChecked,
                        }}
                    />
                </section>

                <Payment
                    showPayment={showPayment}
                    paymentMethodsList={paymentMethodsList}
                    title={props.choosePaymentMethodTitle}
                    setPaymentMethod={setPaymentMethod}
                    paymentMethod={paymentMethod}
                    doPayment={doPayment}
                    checkoutLoading={checkoutLoading}
                    confirmPaymentButtonText={props.confirmPaymentButtonText}
                    paymentFreeButtonText={props.paymentFreeButtonText}
                    showCheckoutError={showCheckoutError}
                    errorText={props.errorText}
                    props={discountProps}
                    setDiscount={setDiscount}
                    setCurrentDiscount={setCurrentDiscount}
                    discount={discount}
                    currentDiscount={currentDiscount}
                    setSubTotal={setSubTotal}
                    paymentInfoGuestEndpoint={paymentInfoGuestEndpoint}
                    paymentInfoMemberEndpoint={paymentInfoMemberEndpoint}
                    options={options}
                    memberHeaders={memberHeaders}
                    guestHeaders={guestHeaders}
                    accessToken={props.accessToken}
                    setCurrentVoucher={setCurrentVoucher}
                    currentVoucher={currentVoucher}
                    setVoucher={setVoucher}
                    voucher={voucher}
                    grandTotalWithoutShipping={grandTotalWithoutShipping}
                    grandTotal={grandTotal}
                    subTotal={subTotal}
                    paymentMethodFree={paymentMethodFree}
                    currencyFormat={props.currencyFormat}
                    setCurrentDiscountCoupon={setCurrentDiscountCoupon}
                    currentDiscountCoupon={currentDiscountCoupon}
                    setDiscountCoupon={setDiscountCoupon}
                    discountCoupon={discountCoupon}
                ></Payment>
            </div>
        </div>
    );
};
