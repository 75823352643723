import React, { useState, useEffect, FormEvent, Dispatch, SetStateAction } from "react";
import cn from 'classnames';

interface ITwoColumnsBlock {
    title?: string;
    intro?: string;
    children?: React.ReactNode;
    link?: {
        text?: string;
        url?: string;
    };
    buttomText?: string;
    onButtonClick?: (val: any) => any;
    inOneCoulmn?: Boolean;
}

const TwoColumnsBlock = ({ title, intro, children, link, buttomText, onButtonClick = () => {}, inOneCoulmn=false }: ITwoColumnsBlock) => {
    const handleClick = () => {
        onButtonClick(true);
    };
    
    return (
        <div className={cn("TwoColumnsBlock", {
            "TwoColumnsBlock--in-one-column": inOneCoulmn
        })}>
            <div className="TwoColumnsBlock__inner">
                <div className="TwoColumnsBlock__column">
                    {title && <h2 className="TwoColumnsBlock__title">{title}</h2>}
                    {intro && (
                        <div className="TwoColumnsBlock__intro" dangerouslySetInnerHTML={{ __html: intro }}></div>
                    )}
                </div>
                <div className={cn("TwoColumnsBlock__column", {
                    "TwoColumnsBlock__column--centered": !(link && buttomText)
                })}>
                    {children ? (
                        <>{children}</>
                    ) : (
                        <>
                            {link && (
                                <a href={link.url} className="TwoColumnsBlock__link button--primary">
                                    {link.text}
                                </a>
                            )}
                            {buttomText && (
                                <button
                                    className="TwoColumnsBlock__button button--secondary"
                                    onClick={handleClick}
                                >
                                    {buttomText}
                                </button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TwoColumnsBlock;
